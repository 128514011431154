.contact-finder-illustration {
	position: relative;
	
	margin-left: -4px;
	max-width: 260px;
	margin-bottom: 4px;
	
    @screen md {
		margin-right: 300px;
		
		max-width: none;
		
    }
    
    @screen lg {
		margin-left: -76px;
		margin-right: -4px;
		
		margin-bottom: 0;
		
    }
    
    @screen xl {
		margin-left: -20px;
		margin-right: 0;
		
    }
    
	&:after {
		content: " ";
		display: block;
		
		width: 100%;
		padding-bottom: 103.639846743295%;
		
		background: url("#{$img-root}/contact-finder/background-illustration.svg") 50%/contain no-repeat;
		
		@screen lg {
			position: absolute;
			
		}
		
	}
	
}

.contact-person {
	img {
		max-width: 224px;
		
		@screen lg {
			max-width: none;
			
		}
    }
    
    + .contact-person {
		margin-top: 20px;
		
    }
    
}
