%footer-link-hover-style {
	&:hover {
        @apply underline;
		text-underline-offset: .3em;
		text-decoration-thickness: 2px;

	}

	@screen sm {
		padding-bottom: 2px;
		border-bottom: 2px solid transparent;
		margin-bottom: -2px;

		&:hover {
			text-decoration: none;
			border-color: currentColor;

		}

	}

}

footer.section {
	display: flex;
	align-items: center;

	position: relative;

	.container {
		margin-top: 80px;
		margin-bottom: 48px;

		@screen sm {
			margin-top: 100px;

		}

		@screen md {
			margin-top: 120px;

		}

		@screen lg {
			// margin-top: 100px;
			margin-bottom: 60px;

		}

		.row {
			height: 100%;

			.column {
				display: flex;
				flex-direction: column;

				align-items: flex-start;
				justify-content: flex-start;

			}

		}

		.footer-links-contact {
			.footer-links {
				@extend %link-style;

				a {
					@extend %footer-link-hover-style;

				}

				> * {
					+ * {
						@apply mt-15;

					}

				}

				@screen md {
					padding-left: 55px;

				}

				@screen lg {
					padding-left: 20px;

				}

				@screen xl {
					padding-left: 30px;

				}

			}

			@media (max-width: 420px) {
				display: block;
				margin-bottom: 0;

				.footer-links {
					display: block;
					padding: 0;
					width: 100%;

					> * {
						display: block;
						margin-top: 0;

						width: 50%;
						float: left;
						padding-left: 10px;

						padding-top: 15px;

					}

				}

				&:after {
					content: " ";
					display: table;
					clear: both;

				}

			}

			.footer-contact {
				@screen lg {
					order: 2;

				}

				h2 {
					margin-top: -.1em;
					margin-bottom: .8em;
					width: 100%;
					max-width: 100%;

				}

				text-align: center;

				margin-bottom: 60px;

				@screen sm {
					margin-bottom: 80px;

				}

				@screen lg {
					margin-bottom: 0;

				}

				@screen md {
					h2 {
						br {
							display: none;

						}

					}

				}

				.button {
					margin-left: auto;
					margin-right: auto;

				}

				@screen lg {
					text-align: left;

					h2 {
						width: auto;

					}

					.button {
						margin-left: 0;
						margin-right: 0;

					}

				}

				@screen xl {
					h2 {
						br {
							display: block;

						}

					}

				}

			}

		}


		.footer-legal-social {
			margin-top: 70px;

			@screen lg {
				margin-top: 90px;

			}

			.column {
				align-items: center;

				&:first-child {
					order: 2;

				}

				@screen xl {
					align-items: flex-start;

					&:first-child {
						order: 0;

					}

				}

			}

			.footer-legal-links {
				display: flex;
				justify-content: center;

				flex-wrap: wrap;

				a {
					@extend %footer-link-hover-style;

				}

				> * {
					@extend %link-style;

					margin-left: 18px;
					margin-right: 18px;

					@screen sm {
						margin-left: 0;

						+ * {
							margin-left: 30px;
							margin-right: 0;

						}

					}

				}

				span {
					order: 10;
					width: 100%;
					text-align: center;
					margin-top: 15px;

				}

				margin-top: 20px;

				@screen sm {
					span {
						order: 0;
						width: auto;
						margin-top: 0;

					}

				}

				@screen md {
					margin-top: 0;

				}

			}

			.footer-social-links {
				display: flex;

				@screen lg {

				}

				.footer-social-link {
					display: block;
					width: 22px;
					height: 22px;

					+ .footer-social-link {
						margin-left: 45px;

					}

					background: 50% no-repeat;

					&-linked-in {
						width: 23px;
						margin-right: -1px;

						background-image: url("#{$img-root}/footer/social-icon-linked-in.svg");

						&:hover {
							background-image: url("#{$img-root}/footer/social-icon-linked-in-hover.svg");

						}

					}

					&-xing {
						background-image: url("#{$img-root}/footer/social-icon-xing.svg");

						&:hover {
							background-image: url("#{$img-root}/footer/social-icon-xing-hover.svg");

						}

					}

					&-facebook {
						background-image: url("#{$img-root}/footer/social-icon-facebook.svg");

						&:hover {
							background-image: url("#{$img-root}/footer/social-icon-facebook-hover.svg");

						}

					}

					&-instagram {
						background-image: url("#{$img-root}/footer/social-icon-instagram.svg");

						&:hover {
							background-image: url("#{$img-root}/footer/social-icon-instagram-hover.svg");

						}

					}

					&-you-tube {
						background-image: url("#{$img-root}/footer/social-icon-you-tube.svg");

						&:hover {
							background-image: url("#{$img-root}/footer/social-icon-you-tube-hover.svg");

						}

					}

				}

			}

		}

	}

}

footer.campaign.section  {
    display: flex;
    align-items: center;

    overflow: hidden;

    > .container {
        margin-top: 80px;
        margin-bottom: 48px;

        @screen sm {
            margin-top: 100px;
            margin-bottom: 60px;
        }

        @screen md {
            margin-top: 120px;
            margin-bottom: 100px;

        }

        @screen xl {
            position: relative;
            z-index: 1;

        }

        > .row {
            justify-content: space-between;
            align-items: center;

            flex-direction: column;

            @screen xl {
                flex-direction: row;
                align-items: stretch;

            }

            > .column {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }

        }

        .footer-contact-column {
            @media (max-width: 1439px) {
                width: auto;
            }

			margin-top: 60px;

            @screen xl {
                order: 2;
				margin-top: 0;

            }

            .footer-contact {
                max-width: 310px;

                @screen sm {
                    max-width: 355px;
                }

                @screen lg {
                    max-width: 470px;
                }

                h2 {
                    @apply font-medium;

                    margin-top: -.1em;
                    margin-bottom: .725em;
                    width: 100%;
                    max-width: 100%;

                }

            }

            .footer-endorsement {
                width: 100%;
                max-width: 520px;
                margin-left: auto;

                margin-top: 60px;

                @screen sm {
                    margin-left: unset;

					min-width: 480px;

                }

                @screen md {
                    margin-top: 90px;

                }

                @screen lg {
                    margin-top: 150px;

                }

                > div {
                    @media (max-width: 560px) {
                        max-width: 335px;
                        margin-left: auto;

                    }

                    &:first-child {
                        margin-bottom: 30px;

                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;

                    }

                    &:last-child {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;

                        .footer-endorsement-mobile-separator {
                            display: none;

                        }

                        @media (max-width: 560px) {
                            flex-wrap: wrap;

                            .footer-endorsement-mobile-separator {
                                display: block;
                                width: 100%;
                                margin-bottom: -10px;

                            }

                        }

                    }

                }

                .footer-endorsement-brands-label {
                    display: none;
                    font-size: 11px;
                    font-weight: 700;
                    margin-top: -0.5em;

                    @screen md {
                        margin-top: -1em;
                    }
                }

            }

        }

    }

    .footer-links-column {
        @apply text-white;

        position: relative;

        margin-top: 45px;

        @screen sm {
            margin-top: 60px;

        }

        @screen md {
            margin-top: 90px;

        }

        @screen xl {
            margin-top: 0;

            &:before {
                content: none;

            }

        }

        .footer-links {
            display: block;

            @extend %link-style;

            a {
                @extend %footer-link-hover-style;

            }

            > * {
                display: table;

                + * {
                    margin-top: 15px;

                }

            }

        }

        @media (max-width: 560px) {
            margin-bottom: 0;

            .row {
                display: block;

            }

            .footer-links {
                display: block;
                padding: 0;
                width: 100%;

                > * {
                    display: block;
                    margin-top: 0;

                    width: 50%;
                    float: left;
                    padding-left: 10px;

                    padding-top: 15px;

                }

            }

            &:after {
                content: " ";
                display: table;
                clear: both;

            }

        }

		.footer-links-column {
			align-items: flex-end;

		}

        .footer-legal-social {
			flex: 0 0 0;
			width: 100%;

			margin-left: 0;
			margin-top: 0;

            @screen xl {
				margin-left: -20px;

            }

            > .column {
                align-items: center;
				justify-content: flex-end;

                &:first-child {
                    order: 2;
					padding-top: 0;

                }

                &:last-child {
					padding-top: 0;
					padding-bottom: 18px;

                }

                @screen xl {
                    align-items: flex-start;

                    &:first-child {
                        order: 0;
						padding-bottom: 0;

                    }

					&:last-child {
						padding-top: 0;
						padding-bottom: 20px;

					}

                }

            }

            .footer-legal-links {
				text-align: left;

                a {
                    @extend %footer-link-hover-style;

                }

                > * {
                    @extend %link-style;

                    margin-left: 18px;
                    margin-right: 18px;

                    @screen sm {
                        margin-left: 0;
                        margin-right: 0;

                        + * {
                            margin-left: 30px;
                            margin-right: 0;

                        }

                    }

                }

                span {
                    order: 10;
                    width: 100%;
                    margin-top: 15px;

					@screen sm {
						order: 0;
						width: auto;
						margin-top: 0;

					}

					text-align: left;

                }

                margin-top: 20px;

                @screen md {
                    margin-top: 0;

                }

                @media (max-width: 559.9999px) {
                    > * {
                        display: block;
                        width: 50%;
                        float: left;

                        margin-left: 0;
                        margin-right: 0;

                        margin-top: 15px;

                    }

                    &:after {
                        content: " ";
                        display: table;
                        clear: both;

                    }

                }

            }

            .footer-social-links {
                display: flex;

                margin-top: 50px;

                @screen sm {
                    margin-top: 24px;

                }

                .footer-social-link {
                    display: block;
                    width: 22px;
                    height: 22px;

                    + .footer-social-link {
                        margin-left: 30px;

                        @screen sm {
                            margin-left: 45px;

                        }

                    }

                    background: 50% no-repeat;

                }

            }

        }

    }

    .dark
    .footer-social-link {
        &-linked-in {
            background-image: url("#{$img-root}/footer/social-icon-linked-in.svg") !important;
        }

        &-xing {
            background-image: url("#{$img-root}/footer/social-icon-xing.svg") !important;
        }

        &-facebook {
            background-image: url("#{$img-root}/footer/social-icon-facebook.svg") !important;
        }

        &-instagram {
            background-image: url("#{$img-root}/footer/social-icon-instagram.svg") !important;
        }

        &-you-tube {
            background-image: url("#{$img-root}/footer/social-icon-you-tube.svg") !important;
        }


    }


    .footer-legal-links {
        color: #14264d;
    }
}
