a.link {
	cursor: pointer;

	&:hover {
		@apply text-green;

	}

	.bg-green &:hover {
		@apply text-blue;

	}
}

%button-style {
	@apply text-sm;

	margin-left: -2px;

	border: 1.4px solid;
	border-radius: 24px;

	padding: 6px 25px;

	@apply border-blue;

}

%button-hover-effect {
	&:after {
		transform: translate(-50%, -50%) scale(1);

		opacity: 1;

	}

}

.button {
	display: inline-block;

	@extend %button-style;

	@apply bg-blue;
	@apply text-white;

	white-space: nowrap;

	&:hover {
		@apply bg-green;
		@apply border-green;

	}

	&.secondary {
		@apply bg-transparent;
		@apply text-blue;

		&:hover {
			@apply border-green;
			@apply text-green;

		}

	}
	&.small {
	    padding: 2px 15px;
	}

	&.primary {
		position: relative;
		z-index: 1;
		overflow: hidden;

		border: none;
		padding: 7px 26px;

		&:after {
			content: " ";
			position: absolute;

			width: 100%;
			aspect-ratio: 1/1;

			left: 50%;
			top: 50%;

			z-index: -1;

			transform: translate(-50%, -50%) scale(0);

			transform-origin: 50% 50%;

			@apply bg-green;
			opacity: .5;

			transition: all .33s;

		}

		&:hover {
			@apply bg-blue;
			@apply text-white;

			@extend %button-hover-effect;

		}

	}

	.bg-blue & {
		@apply bg-white;
		@apply border-white;
		@apply text-blue;

		&:hover {
			@apply bg-green;
			@apply border-green;
			@apply text-white;

		}

		&.secondary {
			@apply bg-blue;
			@apply text-white;

			&:hover {
				@apply border-green;
				@apply text-green;

			}

		}

	}

	.bg-green & {
		@apply bg-white;
		@apply border-white;
		@apply text-green;

		&:hover {
			@apply bg-blue;
			@apply border-blue;
			@apply text-white;

		}

		&.secondary {
			@apply bg-green;
			@apply text-white;

			&:hover {
				@apply border-blue;
				@apply text-blue;

			}

		}

	}

}

a:hover {
	.button {
		&:not(.primary, .secondary) {
			@apply bg-green;
			@apply border-green;

		}

		&.primary {
			@extend %button-hover-effect;

		}

		&.secondary {
			@apply bg-transparent;
			@apply border-green;
			@apply text-green;

		}

	}

	.bg-blue, &.bg-blue, .bg-blue & {
		.button {
			@apply bg-green;
			@apply border-green;
			@apply text-white;

			&.secondary {
				@apply bg-blue;
				@apply border-green;
				@apply text-green;

			}

		}

	}

	.bg-green, &.bg-green, .bg-green & {
		.button {
			@apply bg-blue;
			@apply border-blue;
			@apply text-white;

			&.secondary {
				@apply bg-green;
				@apply border-blue;
				@apply text-blue;

			}

		}

	}

}

.text-link, .text-link-highlight {
	display: inline-block;
	padding-right: 1em;

	&:after {
		content: " ";
		display: inline-block;

		width: 1em;
		margin-right: -1em;
		height: .55em;

		background: url("#{$img-root}/ui/text-link-arrow-blue.svg") 100% 50%/contain no-repeat;

		position: relative;
		top: .025em;

		body.IE & {
			background-position: 85% 50%;

		}

	}

	&:hover {
		@apply text-green;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-green.svg");

		}

	}

	.bg-blue &,
	.bg-green & {
		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-white.svg");

		}

	}

	.bg-blue &:hover {
		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-green.svg");

		}

	}

	.bg-green &:hover {
		@apply text-blue;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-blue.svg");

		}

	}

}

.text-link {
    @apply text-sm;

    @screen md {
        @apply text-md;
    }

	@apply leading-relaxed;

}

.text-link-highlight {
	@apply text-xl;
	@apply leading-snug;

}

a:hover {
	.text-link, .text-link-highlight {
		@apply text-green;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-green.svg");

		}

	}

	.bg-blue, &.bg-blue, .bg-blue & {
		.text-link, .text-link-highlight {
			&:after {
				background-image: url("#{$img-root}/ui/text-link-arrow-green.svg");

			}

		}

	}

	.bg-green, &.bg-green, .bg-green & {
		.text-link, .text-link-highlight {
			@apply text-blue;

			&:after {
				background-image: url("#{$img-root}/ui/text-link-arrow-blue.svg");

			}

		}

	}

}

.video-archive-link {
    display: inline-block;
    padding-left: 1.25em;
    text-decoration: underline;

    &:before {
        content: " ";
        display: inline-block;

        width: 1em;
        margin-left: -1em;
        height: 1em;

        background: url("#{$img-root}/ui/video-archive-link.svg") 0 0/contain no-repeat;

        position: relative;
        top: .15em;
        left: -0.2em
    }

    &:hover{

        &:before {
            background: url("#{$img-root}/ui/video-archive-link-green.svg") 0 0/contain no-repeat;
        }
    }
}
