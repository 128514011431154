.background-shape-container {
	position: relative;
	
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	
	&-header {
		margin-top: 95px;
		margin-bottom: -20px;
		
		@screen md {
			margin-bottom: -15px;
			
		}
		@screen lg {
			margin-top: 99px;
			margin-bottom: 20px;
			
		}
		@screen xl {
			margin-bottom: 80px;
			
		}
		
		+ main > .neos-contentcollection:first-child > .container:first-child > .row:first-child {
			h1.sievert-logo-square {
				margin-top: 0;
				
			}
			
		}
		
	}
	
	&-footer {
		margin-bottom: -75px;
		
		@apply mt-60;
		
		@screen md {
			margin-bottom: -60px;
			@apply mt-90;
		}
		
		@screen-xl {
			@apply mt-150;
			
		}
		
	}
	
	&-bottom {
		display: none;
		
	}
	
	.background-shape {
		position: absolute;
		z-index: 1;
		
		width: 5/7 * 100%;
		aspect-ratio: 5/1;
		
		@screen sm {
			width: 50%;
			
		}
		
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		
		> * {
			&:nth-child(1) {
				background: #14264d;
				
			}
			&:nth-child(2) {
				background: #e6000f;
				
			}
			&:nth-child(3) {
				background: #00a887;
				
			}
			&:nth-child(4) {
				background: #ff6b00;
				
			}
			&:nth-child(5) {
				background: #85bc22;
				
			}
			
		}
		
	}
	
	[data-class-name=ImageSequencer] {
		position: relative;
		z-index: 0;
		
		width: 100%;
		
		aspect-ratio: 1/1;
		max-height: 375px;
		
		@screen md {
			min-height: 375px;
			max-height: none;
			
		}
		
		img, picture {
			position: absolute;
			z-index: -1;
			
			width: 100%;
			height: 100%;
			max-height: 960px;
			
			object-fit: cover;
			object-position: 50% 50%;
			
		}
		
	}
	
	&-image-shape-left {
		[data-class-name=ImageSequencer] {
			@screen sm {
				aspect-ratio: 760/380;
				
			}
			@screen md {
				aspect-ratio: 1024/480;
			}
			@screen lg {
				aspect-ratio: 1800/800;
				
			}
			
		}
		
	}
	
	&-image-shape-right {
		.background-shape {
			@screen md {
				right: 0;
				
			}
			
		}
		
		[data-class-name=ImageSequencer] {
			@screen sm {
				aspect-ratio: 560/380;
				
			}
			@screen md {
				width: 8/10 * 100%;
				aspect-ratio: 820/480;
				
			}
			@screen lg {
				aspect-ratio: 1440/800;
				
			}
			
		}
		
	}
	
	&-shape-left,
	&-shape-right {
		.background-shape {
			position: static;
			
		}
		
	}
	
	&-shape-right {
		.background-shape {
			margin-left: auto;
			
		}
		
	}
	
}
