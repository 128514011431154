@tailwind base;
@tailwind components;

/**
 * Available path aliases: @sievert & @tubag
 * for the asset directories in project
 */

@import "_variables";
@import "_primary-color";
@import "_base-fx";
/*! purgecss start ignore */
@import "_grid";
// @import "_grid-debug";
@import "_typography";
@import "_interaction";
@import "_ui";
@import "_tables";
@import "_images";
@import "_illustrations";
@import "_navigation";
@import "_footer";
@import "_background-shapes";
@import "_accordion";
@import "_gallery";
@import "_collage";
@import "_video";
@import "_sievert-logo-square";
@import "_richtext";
@import "_forms";
@import "_forms-vkf-reward";
@import "_forms-hahne-event";
@import "_campaign";
@import "_downloadList";
@import "_detail-drawings";
@import "_detail-drawings-sievert";
@import "_sustainability";
/*! purgecss end ignore */

@tailwind utilities;

.column-py-0 .column {
    @apply .py-0;
}
