form#vkf-reward-form {
	
	%form-two-column-container {
		display: flex;
		@apply w-full;
		
		flex-direction: column;
		
		@screen md {
			flex-direction: row;
			
		}
		
		> * {
			@extend .column;
			
			@apply w-full;
			flex: 1 0 auto;
			
			@screen md {
				&:first-child {
					@apply w-3/12;
					
				}
				
				&:last-child {
					@apply w-6/12;
					
				}
				
			}
			
		}
		
	}
	
	%form-row-space {
		@apply mt-30;
		
		@screen md {
			@apply mt-60;
			
		}
		
	}
	
	%form-radio-button {
		position: relative;
		
		&:before {
			content: "";
			position: absolute;
			left: .4em;
			top: .4em;
			
			width: 1.5em;
			height: 1.5em;
			
			border-radius: 50%;
			border: 2px solid currentColor;
			
		}
		
	}
	
	%form-radio-button-checked {
		&:before {
			border-color: var(--primary-color);
			background: var(--primary-color);
			
		}
		
	}
	
	.form-upload-row > div {
		
		@extend %form-row-space;
		margin-top: 0;
		margin-bottom: 30px;
		
		display: flex;
		flex-direction: column;
		
		@apply w-full;
		
		> * {
			@extend .column;
			
			@apply w-full;
			
			&:first-child {
				order: 2;
				
				padding-top: 0;
				
			}
			&:last-child {
				order: 1;
				
				padding-top: 60px;
				padding-bottom: 0;
				
			}
			
		}
		
		input[type=file] {
			cursor: pointer;
			
			border: none;
			padding: 0;
			padding-top: 20px;
			
			&::file-selector-button {
				cursor: pointer;
				@extend .button;
				background: #000000;
				margin-left: 0;
				
			}
			
			&:hover {
				&::file-selector-button {
					background: var(--primary-color);
					border-color: var(--primary-color);
					
				}
				
			}
			
		}
		
		label {
			cursor: pointer;
			
			display: block;
			@extend %copy-text;
			
		}
		
		.invalid-feedback {
			order: 3;
			padding-top: 30px;
			
		}
		
	}
	
	.rewards .form-check {
		> div {
			@extend %form-row-space;
			
		}
		
		input {
			position: absolute;
			display: none;
			
		}
		
		label {
			cursor: pointer;
			
			@extend %form-two-column-container;
			margin-left: 0;
			
			h3 {
				@extend .h2;
				font-weight: 600;
				
				margin-bottom: .1em;
				
			}
			
			> div:first-child > div {
				@extend %form-radio-button;
				
			}
			
		}
		
		input:checked + label {
			> div:first-child > div {
				@extend %form-radio-button-checked;
				
			}
			
		}
		
		> :nth-child(3) {
			label {
				padding-left: 1.75em * 2;
				
				@extend %form-radio-button;
				
				&:before {
					top: .275em;
					margin: 10px;
					
					@screen md {
						margin: 20px;
					}
					@screen xl {
						margin: 30px;
					}
					
				}
				
			}
			
			input:checked + label {
				@extend %form-radio-button-checked;
				
			}
			
		}
		
		> .invalid-feedback {
			@extend .column;
			
		}
		
	}
	
	.text-sm {
		@extend %copy-text;
		
	}
	
	.form-check.form-check-legal {
		input {
			display: none;
			
		}
		
		label {
			cursor: pointer;
			
			display: block;
			margin-left: 0;
			padding-left: 2.25em;
			
			@extend %copy-text;
			
			position: relative;
			
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: .1em;
				
				width: 1.25em;
				height: 1.25em;
				
				border: 2px solid currentColor;
				
			}
			
		}
		
		input:checked + label {
			&:before {
				border-color: var(--primary-color);
				background: var(--primary-color) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiBub25lOwogICAgICAgIHN0cm9rZTogIzAwMDsKICAgICAgICBzdHJva2UtbWl0ZXJsaW1pdDogMTA7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiA3cHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyOC43LjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiAxLjIuMCBCdWlsZCAxMzYpICAtLT4KICA8Zz4KICAgIDxnIGlkPSJFYmVuZV8xIj4KICAgICAgPHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMCAzNS45IDI1LjIgNTEuMSA1My45IDkiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPg==") 50%/contain no-repeat;
				
			}
			
		}
		
	}
	
	.button.primary {
		&:hover {
			background: var(--primary-color);
			
		}
		
		&:after {
			content: none;
			
		}
		
	}
	
}
