/* roboto-100 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-100.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-100.woff") format("woff");
}

/* roboto-100italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-100italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-100italic.woff") format("woff");
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-300.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-300.woff") format("woff");
}

/* roboto-300italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-300italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-300italic.woff") format("woff");
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-regular.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-regular.woff") format("woff");
}

/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-italic.woff") format("woff");
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-500.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-500.woff") format("woff");
}

/* roboto-500italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-500italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-500italic.woff") format("woff");
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-700.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-700.woff") format("woff");
}

/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-700italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-700italic.woff") format("woff");
}

/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-900.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-900.woff") format("woff");
}

/* roboto-900italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: local(""),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-900italic.woff2") format("woff2"),
       url("/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Styles/fonts/roboto-v29-latin/roboto-v29-latin-900italic.woff") format("woff");
}

h1, .h1 {
	@apply font-medium;
	@apply leading-tight;

	@apply text-3xl;

	@screen md {
		@apply text-5xl;

	}

	@screen lg {
		@apply text-6xl;

	}

}

/* headline below convex or concave header image */
.transparent-header h1 {
    margin-top: 0;
}

h2, .h2 {
	@apply font-medium;
	@apply leading-snug;

	@apply text-xl;

	@screen md {
		@apply text-2xl;

	}

	@screen lg {
		@apply text-4xl;

	}

}

.story-teaser-headline {
    padding-top: 0.5em;

    @apply w-10/12;

    @screen md {
        @apply w-9/12;

    }

    @screen lg {
        @apply w-full;

    }
	
    &, + .button {
      position: relative;
      z-index: 1;
      
    }
      
}

h3, .h3 {
	@apply font-medium;
	@apply leading-normal;

	@apply text-lg;

	@screen md {
		@apply text-xl;

	}

}

h4, .h4 {
	@apply font-medium;
	@apply leading-normal;

	@apply text-sm;

	@screen md {
		@apply text-md;

	}

}

p.leading, .leading, .rich-text {
	a {
		text-decoration: underline;
		&:hover {
			@apply text-green;
		}
	}
}

p.leading, .leading {
	@apply text-lg;
	@apply leading-normal;

	@screen md {
		@apply text-xl;

	}

	/*
	br + br {
		display: none;

	}
	*/
}

p, .p, %copy-text {
	@apply font-normal;
	@apply leading-relaxed;

	@apply text-sm;

	@screen md {
		@apply text-md;

	}

}

h1 + .leading {
	margin-top: 1em;

}

%link-style {
	@apply text-xs;
	@apply leading-loose;

	@screen md {
		@apply text-sm;

	}

}

.link {
	display: table;

	@extend %link-style;

	+ .link {
		@apply mt-15;

	}

}

p.info-text, .info-text {
	@apply font-medium;
	@apply text-xs;
}

.neos-backend .whitespace-no-wrap {
    position: relative;
    padding: 0 .25em;

    &:after {
        content: " ";
        position: absolute;
        @apply inset-0;

        border-left: 2px dotted;
        border-right: 2px dotted;
        border-bottom: 2px dotted;
        transform: translateY(.15em);

        opacity: .425;

        pointer-events: none;

    }
}

body {
	font-family: "Roboto", "Arial", sans-serif;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;

	@apply text-blue;

	@extend %copy-text;

}
