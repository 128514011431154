body.sustainability {
	
	.page-header .page-header-cover {
		display: none !important;
		
		will-change: transform, clip-path;
		background: rgba(255, 255, 255, .2);
		-webkit-backdrop-filter: none;
		backdrop-filter: none;
		
	}
	
	.background-shape-container-top .background-mask-concave {
		&:before,
		&:after {
			background: transparent;
			
		}
		
		&:after {
			height: 48vw;
			
		}
		
	}
	
	.background-shape-container-bottom .background-shape-concave:after {
		background-image: url("#{$img-root}/sustainability/nachhaltigkeit-footer-dummmy.jpg");
		background-size: 100% !important;
		
	}
	
	.background-shape-container .background-shape img {
		object-position: 50% 50%;
		min-height: 50vw;
	}
	
	.background-shape-container-top {
		height: 40vw;
		
	}
	
	[data-class-name=SustainabiltityLoop] {
		position: relative;
		z-index: -1;
		
		aspect-ratio: 16 / 9;
		
		video {
			position: absolute;
			width: 100%;
			
		}
		
		&.header-loop {
			margin-bottom: -56.25%;
			
			margin-top: 96px;
			
			@screen lg {
				margin-top: 99px;
				
			}
			
		}
		
		&.footer-loop {
			position: absolute;
			bottom: 0;
			
			width: 100%;
			
			video {
				transform: scale(-1, -1);
				opacity: .33;
				
			}
			
		}
		
	}
	
	&:not(.transparent-header) [data-class-name=SustainabiltityLoop] {
		&.header-loop {
			overflow: hidden;
			
			video {
				margin-top: -5%;
				
			}
			
		}
		
	}
	
	h1 {
		color: #85bc22;
		font-weight: 800;
		font-size: 80px;
		
		font-size: 8vw;
		
	}
	
	.sievert-logo-square:before {
		display: none;
		
	}
	
	.bg-blue {
		background-color: #ffffff;
		
		.container {
			width: 100%;
			max-width: 1800px !important;
			
		}
		
		img {
			min-width: 100% !important;
			
		}
		
	}
	
	a.teaser {
		.button.secondary {
			background-color: #85bc22;
			border-color: #ffffff;
			color: #ffffff;
			
			&:hover {
				background-color: #ffffff;
				border-color: #85bc22;
				color: #85bc22;
				
			}
			
		}
		
	}
	
	.bg-warmgray .button {
		background-color: #85bc22;
		border-color: #ffffff;
		color: #ffffff;
		
		&:hover {
			background-color: #ffffff;
			border-color: #85bc22;
			color: #85bc22;
			
		}
		
		&.secondary {
			background-color: #ffffff;
			border-color: #85bc22;
			color: #85bc22;
			
			&:hover {
				background-color: #ffffff;
				// border-color: rgb(20, 38, 77); // #85bc22;
				// color: rgb(20, 38, 77); // #85bc22;
				
			}
			
		}
		
	}
	
	.bg-warmgray {
		background-color: transparent;
		
		p {
			color: #85bc22 !important;
			
		}
		
		h2, h3 {
			color: #85bc22 !important;
			
		}
		
	}
	
	.footer-links-contact {
		.button.secondary {
			&:hover {
				color: #ffffff;
				background-color: rgb(20, 38, 77);
				border-color: rgb(20, 38, 77);
				
				// border-color: rgb(20, 38, 77);
				// color: rgb(20, 38, 77);
				
			}
			
		}
		
	}
	
}
